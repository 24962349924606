import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { AnimatePresence, LazyMotion, m, domAnimation } from 'framer-motion';
import dynamic from 'next/dynamic';

import { CATEGORIES } from 'utils/philingCategories';
const GenderModal = dynamic(() => import('components/molecules/PhilingModal/GenderModal'));

export default function PhilingMainHeaderNav() {
  const [categories, setCategories] = useState(CATEGORIES);
  const [openGenderModal, setOpenGenderModal] = useState(false);
  const router = useRouter();

  const onClickTab = (idx) => () => {
    // const isCurrentTab = categories[idx].isActive;
    // if (isCurrentTab) return;

    // setCategories(categories.map((v, i) => ({ ...v, isActive: i === idx })));
    if (categories[idx].to === '1') {
      setOpenGenderModal(true);
    } else {
      router.push(`/philing/${categories[idx].to}`);
    }
  };

  const onClickCloseModal = () => {
    setOpenGenderModal(false);
  };

  return (
    <>
      <LazyMotion features={domAnimation}>
        <Container
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -100, opacity: 0, transition: { duration: 0.15 } }}
          transition={{ duration: 0.2 }}
        >
          <TabRow>
            {categories.map(({ label }, idx) => (
              <Tab key={idx} onClick={onClickTab(idx)}>
                {label}
              </Tab>
            ))}
          </TabRow>
        </Container>
      </LazyMotion>

      <AnimatePresence>
        {openGenderModal && <GenderModal onClickCloseModal={onClickCloseModal} />}
      </AnimatePresence>
    </>
  );
}

const Container = styled(m.div)`
  padding: 1rem 1.5rem;
  position: fixed;
  top: 4.5rem;
  left: auto;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  margin-left: -1.4rem;
  background-color: #fff;
  border-bottom: 1px solid #bbbbbb;
  z-index: 99;
`;

const TabRow = styled.div`
  display: flex;
`;

const Tab = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.5rem 1.7rem;
  margin-right: 0.5rem;
  border-radius: 2rem;
  background-color: ${({ isActive }) => isActive && '#e1ea68'};
  cursor: pointer;
`;
