import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import ProgressiveImage from 'react-progressive-graceful-image';
import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import { Fade } from '@egjs/flicking-plugins';
import '@egjs/react-flicking/dist/flicking.css';
import YouTube from 'react-youtube';

const IconArrowSmall = '/assets/images/icons/icon_arrow_small.png';
const IconArrowSmallWhite = '/assets/images/icons/icon_arrow_small_white.png';

const STEPS = [
  {
    src: 'https://images.odoctor.co.kr/community/post/upload/BnakVVacW1DOVoxmsAXkB_KakaoTalk_Photo_2024-01-08-14-33-56-002.webp',
    title: '나이들수록 심해지는\n불독살 해결했어요',
    desc: '',
  },
  {
    src: 'https://images.odoctor.co.kr/community/post/upload/lMc_HLFdWf3QwpqVQXknP_%E1%84%89%E1%85%B3%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AB%E1%84%89%E1%85%A3%E1%86%BA-2024-01-08-%E1%84%8B%E1%85%A9%E1%84%8C%E1%85%A5%E1%86%AB-11.54.15.webp',
    title: '안면거상술로\n10년전 얼굴 되찾았어요',
    desc: '',
  },
  {
    src: 'https://images.odoctor.co.kr/community/post/upload/NPcbsgoZN_SwAJleF_iza_KakaoTalk_Photo_2024-01-08-14-09-46-002.webp',
    title: '임플란트 없이\n밝고 가지런한 치아로?',
    desc: '',
  },
];

const opts = {
  height: '100%',
  width: '100%',
  modestbranding: 0,
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

const YPlayer = ({ children, id }) => {
  const [p, setP] = useState(null);
  const [s, setS] = useState(true);
  const onRd = (e) => {
    setP(e);
  };

  const onPs = (e) => {
    setS(true);
  };

  return (
    <>
      <YouTube videoId={id} opts={opts} onReady={onRd} onPause={onPs} />

      <div
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 999,
          display: s ? 'block' : 'none',
        }}
        onClick={() => {
          p.target.playVideo();
          setS(false);
        }}
      >
        {children}
      </div>
    </>
  );
};

export default function SectionC({ images = [], isSubMain, onClickBtnPaper, stepInfo }) {
  const router = useRouter();
  const [currentIndex, setCurrentIndex] = useState(0);
  const plugins = [new Fade()];
  const ref = useRef(null);

  useEffect(() => {
    setCurrentIndex(0);
    ref.current.init();
    ref.current.on('willChange', onWillChange);
    return () => {
      if (ref.current) {
        ref.current.destroy();
      }
    };
  }, [router.asPath]);

  const onWillChange = ({ index }) => {
    setCurrentIndex(index);
  };

  return (
    <Container>
      <InView triggerOnce threshold={0.5}>
        {({ inView, ref }) => (
          <Text ref={ref} inView={inView}>
            <Bold>동안클럽</Bold>
          </Text>
        )}
      </InView>
      <InView triggerOnce threshold={0.5}>
        {({ inView, ref }) => (
          <DescriptionWrap ref={ref} inView={inView}>
            {stepInfo ? (
              <Description>{stepInfo.ko}</Description>
            ) : (
              <>
                <Description>&quot;젊어져라&quot;와 함께</Description>
                <Description>진짜 젊어진 멤버들의 이야기를 들어보세요</Description>
              </>
            )}
          </DescriptionWrap>
        )}
      </InView>
      <CarouselQA>
        <Flicking>
          {STEPS.map(({ src, title, desc }, idx) => (
            <Item key={idx}>
              <Image src={src} alt={title} />
              <Info>
                <Title>{title}</Title>
                <Desc>{desc}</Desc>
              </Info>
            </Item>
          ))}
        </Flicking>
      </CarouselQA>
      <Button
        isSubMain={isSubMain}
        onMouseUp={() =>
          isSubMain
            ? onClickBtnPaper()
            : window.scrollBy({ top: -window.scrollY, left: 0, behavior: 'smooth' })
        }
      >
        <span>더 많은 멤버들의 이야기 보기</span>
        <img src={isSubMain ? IconArrowSmallWhite : IconArrowSmall} alt="커뮤니티 이동" />
      </Button>
      <FullSizeCarouselWrapper>
        <FullSizeCarousel>
          <Flicking
            plugins={plugins}
            preventClickOnDrag
            onWillChange={onWillChange}
            autoInit={false}
            ref={ref}
          >
            {images.map(({ src, title, desc, pre, youtubeId }, idx) => (
              <Wrap key={idx} onClick={() => isSubMain && onClickBtnPaper()}>
                <ProgressiveImage src={src} placeholder={pre}>
                  {(src, loading) => {
                    return (
                      <Ytwrap>
                        <YPlayer id={youtubeId}>
                          <FullSizeImage
                            style={{ opacity: loading ? 0.5 : 1 }}
                            src={src}
                            alt={title}
                          />
                        </YPlayer>
                      </Ytwrap>
                    );
                  }}
                </ProgressiveImage>
              </Wrap>
            ))}
            <ViewportSlot>
              <Indicator>
                {images.map((image, idx) => (
                  <Bullet key={idx} active={currentIndex === idx} />
                ))}
              </Indicator>
            </ViewportSlot>
          </Flicking>
        </FullSizeCarousel>
      </FullSizeCarouselWrapper>
    </Container>
  );
}

const Ytwrap = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
  -webkit-user-drag: none;
  // aspect-ratio: 9 / 16;

  overflow: hidden;
  & > div {
    height: 100%;
  }
`;

const Container = styled.div`
  margin: 0 -1.4rem;
  padding: 1.4rem;
  padding-top: 5.5rem;
`;

const Text = styled.div`
  font-family: 'Heebo';
  font-size: 3rem;
  transform: translateY(${({ inView }) => (inView ? '0' : '-3rem')});
  opacity: ${({ inView }) => (inView ? '1' : '0')};
  transition: all 1.5s ease 0.3s;
`;

const Bold = styled.span`
  font-family: 'Taviraj';
  font-style: italic;
  font-size: 3rem;
  font-weight: bold;
`;

const DescriptionWrap = styled.div`
  margin-top: 1rem;
  transform: translateY(${({ inView }) => (inView ? '0' : '-3rem')});
  opacity: ${({ inView }) => (inView ? '1' : '0')};
  transition: all 1.5s ease 0.3s;
`;

const Description = styled.div`
  font-size: 1.6rem;
  white-space: pre-line;
`;

const CarouselQA = styled.div`
  background-color: #eeeeee;
  border-radius: 2rem;
  margin-top: 5rem;
  padding: 1rem 0 3.5rem 0;
`;

const Item = styled.div`
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  border-radius: 50%;
`;

const Image = styled.img`
  padding: 2rem;
  width: 26.5rem;
  -webkit-user-drag: none;
  aspect-ratio: 1;
  border-radius: 30%;
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 1.6rem;
`;

const Desc = styled.div`
  margin-top: 0.5rem;
  font-size: 1.2rem;
`;

const Button = styled.button`
  margin-top: 1rem;
  width: 100%;
  font-size: 1.4rem;
  display: flex;
  padding: 1.8rem 2rem;
  background-color: ${({ isSubMain }) => (isSubMain ? '#666666' : '#e1ea68')};
  color: ${({ isSubMain }) => (isSubMain ? '#fff' : '#000')};
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;

  &:active {
    transform: translateY(0.3rem);
  }

  img {
    width: 2.8rem;
  }
`;

const FullSizeCarouselWrapper = styled.div`
  margin-top: 5rem;
  position: relative;
`;

const FullSizeCarousel = styled.div`
  position: relative;
`;

const Wrap = styled.div`
  width: 100%;
  padding-top: 160%;
  position: relative;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

const DescWrap = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40%;
  padding: 2.5rem;
  padding-bottom: 3.5rem;

  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  background: linear-gradient(#00000000, #00000099);
`;

const DescTitle = styled.div`
  line-height: 4rem;
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  display: inline-flex;
  white-space: pre-line;
  color: #fff;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const DescInfo = styled.div`
  white-space: pre-line;
  font-size: 1.4rem;
  display: inline-flex;
  color: #fff;
`;

const FullSizeImage = styled.img`
  width: 100%;
  border-radius: 2rem;
  -webkit-user-drag: none;
  aspect-ratio: 83 / 143;
`;

const Indicator = styled.div`
  margin-top: 2rem;
  display: flex;
`;

const Bullet = styled.div`
  display: flex;
  width: 4rem;
  height: 0.5rem;
  background-color: ${({ active }) => (active ? '#e1ea68' : '#cccccc')};
  margin-right: 0.4rem;
  border-radius: 0.4rem;
  transition: all 0.5s ease 0.3s;
  cursor: pointer;
`;
