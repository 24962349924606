import React from 'react';
import styled from 'styled-components';

import FaqCard from 'components/atoms/FaqCard';

export default function SectionE({ questions = [] }) {
  return (
    <Container>
      <Title>FAQ</Title>
      <Wrap>
        {questions.map(({ label, link }, idx) => (
          <FaqCard key={idx} question={label} link={link} newTab />
        ))}
      </Wrap>
      <InquiryPartner>
        젊어져라와 함께 성장하세요 🏥
        <br />
        <InquiryButton onClick={() => window.open('https://contents.imweb.me/295')}>
          <em>파트너 병원 입점문의</em>
        </InquiryButton>
      </InquiryPartner>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 -1.4rem;
  background-color: #eeeeee;
  padding: 0 1.4rem;
  padding-top: 5.5rem;
  padding-bottom: 5rem;
`;

const Title = styled.div`
  font-family: 'Taviraj';
  font-style: italic;
  font-size: 3rem;
  font-weight: bold;
`;

const Wrap = styled.div`
  margin-top: 3rem;
`;

const InquiryPartner = styled.div`
  margin: 5rem 0 -1rem;
  padding: 1rem;
  line-height: 3;
  background-color: #e1ea68;
  border-radius: 2rem;
  text-align: center;
  font-size: 1.4rem;
  color: #333;
  font-weight: bold;
`;

const InquiryButton = styled.button`
  padding: 0.8rem 7rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #fff;
  border-radius: 2rem;
  text-align: center;
`;
