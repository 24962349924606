import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import ProgressiveImage from 'react-progressive-graceful-image';
import dynamic from 'next/dynamic';

const GenderModal = dynamic(() => import('components/molecules/PhilingModal/GenderModal'));

const IconArrow = '/assets/images/icons/icon_arrow.png';

export default function PhilingMainCard({
  title = '',
  content = '',
  link = '',
  image = '',
  pre = '',
  color,
}) {
  const [openGenderModal, setOpenGenderModal] = useState(false);
  const router = useRouter();
  const [normalText, boldText] = title.split(' ');

  const onClickCard = (to) => () => {
    if (to === '/philing/1') {
      setOpenGenderModal(true);
    } else {
      router.push(to);
    }
  };

  const onClickCloseModal = () => {
    setOpenGenderModal(false);
  };

  return (
    <>
      <Card color="#fff">
        <ProgressiveImage src={image} placeholder={pre}>
          {(src, loading) => (
            <Image
              style={{
                opacity: loading ? 0.5 : 1,
                width: image.includes('main_02.png') && '14rem',
              }}
              src={src}
              alt={title}
            />
          )}
        </ProgressiveImage>
        <Content onClick={onClickCard(link)}>
          <Row>
            <div>
              <NormalText>{normalText} </NormalText>
              <BoldText>{boldText}</BoldText>
            </div>
          </Row>
          <Description>{content}</Description>
        </Content>
        <Arrow>
          <img src={IconArrow} alt="바로가기" />
        </Arrow>
      </Card>
      <AnimatePresence>
        {openGenderModal && <GenderModal onClickCloseModal={onClickCloseModal} />}
      </AnimatePresence>
    </>
  );
}

const Card = styled.div`
  height: 19rem;
  border-radius: 2rem;
  background: ${({ color }) => color};
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
  margin-bottom: 1rem;
  transition-property: scale, opacity;
  transition-duration: 1s, 1s;
  will-change: transform;
  &:hover > img {
    transform: scale(1.1);
  }
`;

const Content = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 3rem 2.5rem;
  transition: all 1s ease;
  cursor: pointer;

  &:link,
  &:visited {
    color: inherit;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NormalText = styled.span`
  font-family: 'Heebo';
  font-size: 2.6rem;
`;

const Image = styled.img`
  position: absolute;
  width: 18rem;
  transition: all 0.3s ease;
  right: 0;
  bottom: 0;
`;

const BoldText = styled.span`
  font-family: 'Heebo';
  font-size: 2.6rem;
  font-weight: bold;
`;

const Description = styled.div`
  white-space: pre-line;
  font-size: 1.2rem;
  line-height: 1.9rem;
  margin-top: 1rem;
`;

const Arrow = styled.div`
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  left: 2.5rem;
  bottom: 2rem;
  background-color: ${({ color }) => color};
  img {
    width: 2.5rem;
  }
`;
