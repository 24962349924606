import styled from 'styled-components';

import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Flicking from '@egjs/react-flicking';

import { drug } from 'components/templates/PhilingSubMainTemplate/drug';

const posts = {
  1: [
    {
      avatar: '/community/avatar/default.png',
      nickname: '화난 치타',
      title: '나이들수록 심해지는 불독살 해결하고 왔습니다',
      content: '아랫볼 불독살이 신경 쓰여서 마흔 넘어서 수술했는데 10년 먼저 할걸 그랬나봐여.',
      comments: 5,
      likes: 3,
      tags: [
        {
          type: 'basic',
          name: '얼굴지방이식',
        },
        {
          type: 'basic',
          name: '볼살',
        },
        {
          type: 'basic',
          name: '불독살',
        },
      ],
    },
    {
      avatar: '/community/avatar/default.png',
      nickname: '화난 치타',
      title: '나이들수록 심해지는 불독살 해결하고 왔습니다',
      content: '아랫볼 불독살이 신경 쓰여서 마흔 넘어서 수술했는데 10년 먼저 할걸 그랬나봐여.',
      comments: 5,
      likes: 3,
      tags: [
        {
          type: 'basic',
          name: '얼굴지방이식',
        },
        {
          type: 'basic',
          name: '볼살',
        },
        {
          type: 'basic',
          name: '불독살',
        },
      ],
    },
    {
      avatar: '/community/avatar/default.png',
      nickname: '화난 치타',
      title: '나이들수록 심해지는 불독살 해결하고 왔습니다',
      content: '아랫볼 불독살이 신경 쓰여서 마흔 넘어서 수술했는데 10년 먼저 할걸 그랬나봐여.',
      comments: 5,
      likes: 3,
      tags: [
        {
          type: 'basic',
          name: '얼굴지방이식',
        },
        {
          type: 'basic',
          name: '볼살',
        },
        {
          type: 'basic',
          name: '불독살',
        },
      ],
    },
  ],
  2: [
    {
      avatar: '/community/avatar/default_w1.png',
      nickname: '세상에나쁜개는없다',
      title: '상하안검+거상술 4개월차 사람들이 놀라네요',
      content:
        '수술 부위가 조금 가려웠는데 지금은 그 가려움증도 없어졌어요~^^ 감각도 지금은 거의 다 돌아온 듯 싶고 젊음이 빨리 지나간 느낌이라 너무 슬펐는데 지금은 다시 되돌려져서 매일 행복합니다^^',
      comments: 0,
      likes: 1,
      tags: [
        {
          type: 'basic',
          name: '상안검',
        },
        {
          type: 'basic',
          name: '리프팅',
        },
        {
          type: 'basic',
          name: '후기',
        },
        {
          type: 'basic',
          name: '하안검',
        },
      ],
    },
    {
      avatar: '/community/avatar/default_cat01.png',
      nickname: '궁금한 아지',
      title: '안면거상술 1달차.. 이제 많이 자연스러워졌어요^^',
      content: '아랫볼 불독살이 신경 쓰여서 마흔 넘어서 수술했는데 10년 먼저 할걸 그랬나봐여.',
      comments: 0,
      likes: 1,
      tags: [
        {
          type: 'basic',
          name: '동안',
        },
        {
          type: 'basic',
          name: '안면거상술',
        },
      ],
    },
    {
      avatar: '/community/avatar/default.png',
      nickname: '화난 치타',
      title: '나이들수록 심해지는 불독살 해결하고 왔습니다',
      content: '아랫볼 불독살이 신경 쓰여서 마흔 넘어서 수술했는데 10년 먼저 할걸 그랬나봐여.',
      comments: 5,
      likes: 3,
      tags: [
        {
          type: 'basic',
          name: '얼굴지방이식',
        },
        {
          type: 'basic',
          name: '볼살',
        },
        {
          type: 'basic',
          name: '불독살',
        },
      ],
    },
  ],
  3: [
    {
      avatar: '/community/avatar/default_m2.png',
      nickname: '라인바싸',
      title: '영원히 살고 싶다.. 실리콘밸리 백만장자.jpg',
      content:
        '서지 파구엣(Serge Faguet, 35)는 실리콘 밸리의 백만장자입니다. 그는 자신이 살아있는 중 기계와 융합되어 수백만년~사실상 불멸의 수명을 가진 사이보그가 될 것이라고 믿습니다. 그러기 위해 그는 25만 달러를 기꺼이 투자하며 더 오래 살 수 있기 위해 몸을 개조하는 바이오 해킹이 취미입니다.',
      comments: 0,
      likes: 1,
      tags: [
        {
          type: 'basic',
          name: '바이오해킹',
        },
      ],
    },
    {
      avatar: '/community/avatar/default.png',
      nickname: '화난 치타',
      title: '나이들수록 심해지는 불독살 해결하고 왔습니다',
      content: '아랫볼 불독살이 신경 쓰여서 마흔 넘어서 수술했는데 10년 먼저 할걸 그랬나봐여.',
      comments: 5,
      likes: 3,
      tags: [
        {
          type: 'basic',
          name: '얼굴지방이식',
        },
        {
          type: 'basic',
          name: '볼살',
        },
        {
          type: 'basic',
          name: '불독살',
        },
      ],
    },
    {
      avatar: '/community/avatar/default.png',
      nickname: '화난 치타',
      title: '나이들수록 심해지는 불독살 해결하고 왔습니다',
      content: '아랫볼 불독살이 신경 쓰여서 마흔 넘어서 수술했는데 10년 먼저 할걸 그랬나봐여.',
      comments: 5,
      likes: 3,
      tags: [
        {
          type: 'basic',
          name: '얼굴지방이식',
        },
        {
          type: 'basic',
          name: '볼살',
        },
        {
          type: 'basic',
          name: '불독살',
        },
      ],
    },
  ],
  4: [
    {
      avatar: '/community/avatar/default.png',
      nickname: '섹시한 강아지',
      title: '영양제 먹으랭',
      content:
        '본인 2년간 폈던 담배 끊고 또 2년간 또 폈다가 또 끊었음 금연 한 이후로 나이가 쳐 들다보니 얼굴이 썩어서 영양제를 먹기 시작했다',
      comments: 2,
      likes: 2,
      tags: [
        {
          type: 'basic',
          name: '영양제',
        },
      ],
    },
  ],
};

export const CATEGORIES = [
  {
    label: '동안비결',
    isActive: true,
    items: drug['1'],
    posts: posts['1'],
    to: '1',
  },
  {
    label: '동안시술',
    isActive: false,
    items: drug['3'],
    posts: posts['2'],
    to: '3',
  },
  {
    label: '바이오해킹',
    isActive: false,
    items: drug['4'],
    posts: posts['3'],
    to: '4',
  },
  {
    label: '영양제',
    isActive: false,
    items: drug['5'],
    posts: posts['4'],
    to: '5',
  },
];

const DrugDescriptionModal = dynamic(() =>
  import('components/molecules/PhilingModal/DrugDescriptionModal')
);

const IconPopup = '/assets/images/icons/icon_popup.png';

const initialCategory = {
  caution: '',
  dosage: '',
  efficacy: '',
  name: { ko: '', en: '' },
  side_effect: '',
  storage_method: '',
  division: '',
  isOpen: false,
};

function PhilingMainCategory({ isSubMain = false, drugs = [] }) {
  const [categories, setCategories] = useState(CATEGORIES);
  const [category, setCategory] = useState(initialCategory);
  const router = useRouter();

  const onClickTab = (idx) => () => {
    const isCurrentTab = categories[idx].isActive;
    if (isCurrentTab) return;

    setCategories(categories.map((v, i) => ({ ...v, isActive: i === idx })));
  };

  const onClickCard = (item) => () => {
    setCategory({ ...item, isOpen: true });
  };

  const [activeCategory] = categories.filter((category) => category.isActive);
  const items = isSubMain ? drugs : activeCategory.items;

  return (
    <Containera>
      {!isSubMain && (
        <TabRow>
          {categories.map(({ label, isActive }, idx) => (
            <Tab key={idx} isActive={isActive} onClick={onClickTab(idx)}>
              {label}
            </Tab>
          ))}
        </TabRow>
      )}
      {/* <div>
        {items.map((item, idx) => (
          <Card key={idx} onClick={onClickCard(item)}>
            <Icon src={IconPopup} />
            <TextEn>{item.name.en}</TextEn>
            <TextKo>{item.name.ko}</TextKo>
            <CategoryInfo>{item.efficacy}</CategoryInfo>
          </Card>
        ))}
        <AnimatePresence>
          {category.isOpen && (
            <DrugDescriptionModal setCategory={setCategory} category={category} />
          )}
        </AnimatePresence>
      </div> */}
      <CardWrapper>
        <Flicking bound className="PickSectionB-viewport" align="prev" preventClickOnDrag>
          {activeCategory.posts.map((post, idx) => (
            <PostCard key={idx}>
              <PostCardBox
                onClick={() => {
                  router.push(`community/post/349`);
                }}
              >
                <div className="author-box">
                  <div className="avatar">
                    <img width={'100%'} src={`https://images.odoctor.co.kr${post.avatar}`} />
                  </div>
                  <div className="author-tags">
                    <div className="author">{post.nickname}</div>
                    <div className={'tagbox'}>
                      {post.tags.map((t, idx) => (
                        <button key={idx} className={`tag tag-${t.type}`}>
                          {t.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="content-wrap">
                  <div className="content-title">{post.title}</div>
                  <div className="content-text">{post.content}</div>
                </div>
                <div className="fun-icons">
                  <div className="comment svg-community-comment">{post.comments} </div>
                  <div className="like svg-community-like">{post.likes} </div>
                </div>
              </PostCardBox>
            </PostCard>
          ))}
        </Flicking>
      </CardWrapper>
    </Containera>
  );
}

const Containera = styled.div`
  margin-top: 3.6rem;
`;

const TabRow = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

const Tab = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.5rem 1.7rem;
  margin-right: 0.5rem;
  border-radius: 2rem;
  background-color: ${({ isActive }) => isActive && '#e1ea68'};
  cursor: pointer;
`;

const Card = styled.div`
  position: relative;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background-color: #fff;
  margin-bottom: 1rem;
  cursor: pointer;

  &:active {
    transform: translateY(0.3rem);
  }
`;

const TextEn = styled.div`
  font-size: 1rem;
  color: #dbcce5;
  font-family: 'Taviraj';
  font-style: italic;
`;

const TextKo = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const CategoryInfo = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.img`
  position: absolute;
  width: 3rem;
  right: 2.5rem;
  top: 2.5rem;
`;

export default function SectionD({ isSubMain = false, drugs = [] }) {
  return (
    <Container>
      <TextWrap>
        <Text>
          젊어지는 커뮤니티<Bold>Best</Bold>
        </Text>
      </TextWrap>
      <Desc>
        <p>젊음, 건강을 위한 유용한 정보와</p>
        <p>재미있는 이야기를 함께 나눠보세요</p>
      </Desc>
      <PhilingMainCategory isSubMain={isSubMain} drugs={drugs} />
    </Container>
  );
}

const Container = styled.div`
  margin: 0 -1.4rem;
  margin-top: 2.2rem;
  background-color: #dccbe5;
  padding: 0 1.4rem;
  padding-top: 5rem;
  padding-bottom: 4rem;
`;

const TextWrap = styled.div`
  padding: 0 0.6rem;
`;

const Text = styled.div`
  font-family: 'Heebo';
  font-size: 3rem;
`;

const Bold = styled.span`
  font-family: 'Taviraj';
  font-style: italic;
  font-size: 3rem;
  font-weight: bold;
`;

const Desc = styled.div`
  margin-top: 3rem;
  padding: 0 0.6rem;
  font-size: 1.4rem;
`;

export const CardWrapper = styled.div`
  margin: 0 -1.4rem;
`;

const PostCard = styled(Card)`
  width: 90%;
  margin-right: 1.4rem;
`;

const PostCardBox = styled.div`
  padding: 2rem;
  background-color: #fff;
  border-radius: 2rem;
  display: grid;
  grid-gap: 1.5rem;
  .author-box {
    display: flex;
  }
  .avatar {
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
    width: 5rem;
    height: 5rem;
  }
  .author {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .author-tags {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .tagbox {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 5px;
    .tag {
      font-size: 1.1rem;
      color: #fff;
      padding: 0.5rem 0.7rem;
      border-radius: 0.7rem;
      background: #f1d3bc;
      &.tag-primary {
        backbround: #f67280;
      }
      &.tag-basic {
        backbround: #f8b195;
      }
    }
  }
  .content-wrap {
    overflow: hidden;
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: auto auto;
    height: 5.5rem;
    .content-title {
      font-size: 1.2rem;
      font-weight: 600;
    }
    .content-text {
      font-size: 1.2rem;
      color: #ccc;
      white-wrap: pre-wrap;
    }
  }
  .fun-icons {
    font-size: 1.1rem;
    color: #99916e;
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-gap: 2rem;
    .comment {
      background-position-x: 0;
      padding-left: 2rem;
    }
    .like {
      background-position-x: 0;
      padding-left: 2rem;
    }
  }
`;
