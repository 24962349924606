import React from 'react';
import styled, { keyframes } from 'styled-components';
import { InView } from 'react-intersection-observer';
import { AnimatePresence } from 'framer-motion';

import PhilingMainCard from 'components/molecules/PhilingMainCard';
import PhilingMainHeaderNav from 'components/atoms/PhilingMainHeaderNav';

const PHILING_CARDS = [
  {
    subject: 'hair',
    title: 'Young Face',
    content: '처진 얼굴살과 주름,\n젊어져라가 엄선한 리프팅으로 땡땡하게',
    image: 'https://images.odoctor.co.kr/philing/card/pt-main-1.png',
    pre: 'https://images.odoctor.co.kr/philing/card/pt-main-1.png',
    color: '#deeb5d',
  },
  {
    subject: 'hair',
    title: 'Clean Skin',
    content: '중장년의 깨끗한 피부를 위한\n맞춤형 시크릿 스킨케어',
    pre: 'https://images.odoctor.co.kr/philing/card/pt-main-2.png',
    color: '#ecafa1',
  },
  {
    subject: 'diet',
    title: 'Bright Smile',
    content: '언제든, 어디서든 밝게 웃을 수 있도록\n예쁜 치아 만들어주는 젊어져라의 해결책',
    image: 'https://images.odoctor.co.kr/philing/card/pt-main-3.png',
    pre: 'https://images.odoctor.co.kr/philing/card/pt-main-3.png',
    color: '#c9acf8',
  },
];

export default function SectionA() {
  return (
    <>
      <Container>
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <TextWrap
              ref={ref}
              inView={inView}
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                margin: '3rem 0',
              }}
            >
              <div
                style={{
                  height: '13rem',
                  width: '13rem',
                }}
              >
                <img width="100%" src="https://images.odoctor.co.kr/unaging/logo/unagingtexg.png" />
              </div>
            </TextWrap>
          )}
        </InView>

        <Top>
          <Rolling>
            <img
              src="https://images.odoctor.co.kr/philing/card/rolling_graphic.webp"
              alt="오닥터"
            />
          </Rolling>
          <Title>
            <InView triggerOnce threshold={0.5}>
              {({ inView, ref }) => (
                <TextWrap ref={ref} inView={inView}>
                  <Text>
                    Regain <Italic>Your Youth</Italic>
                  </Text>
                </TextWrap>
              )}
            </InView>
          </Title>
        </Top>
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <SubTitleWrapper ref={ref} inView={inView}>
              {/* <PenImage src={ImgTextHighlight} /> */}
              <SubTitle>
                <Mark>나다운 나를 만나는 일.</Mark>
              </SubTitle>
            </SubTitleWrapper>
          )}
        </InView>
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <DescriptionWrap ref={ref} inView={inView}>
              <Description>젊음이 흘러가는 것을 바라만 보지 마세요/</Description>
              <Description>젊음을 되찾을 수 있도록 도와드릴게요.</Description>
            </DescriptionWrap>
          )}
        </InView>
      </Container>

      <div>
        {PHILING_CARDS.map((card, idx) => (
          <InView triggerOnce key={idx}>
            {({ inView, ref }) => (
              <Wrap inView={inView} ref={ref}>
                <PhilingMainCard {...card} />
              </Wrap>
            )}
          </InView>
        ))}
      </div>
    </>
  );
}

const Wrap = styled.div`
  transition: all 1s ease 0.3s;
  opacity: ${({ inView }) => (inView ? '1' : '0')};
`;

const spin = keyframes`
  0% {
    transform rotate(360deg)
  }
  50% {
    transform rotate(180deg)
  }
  100% {
    transform rotate(0deg)
  }
`;

const Top = styled.div`
  position: relative;
`;

const Rolling = styled.div`
  position: absolute;
  transform: translate(15rem, -19rem);
  img {
    width: 45rem;
    animation: ${spin} infinite linear 30s;
  }
`;

const Container = styled.div`
  padding-bottom: 5rem;
  margin: 0 -1.4rem;
  padding: 0 1.4rem 5rem 1.4rem;
  overflow: hidden;
  margin-top: -2rem;
`;

const Title = styled.div`
  position: relative;
  margin-left: 0.6rem;
`;

const TextWrap = styled.div`
  transition: all 0.5s ease 0.3s;
  transform: translateY(${({ inView }) => (inView ? '0' : '-3rem')});
  opacity: ${({ inView }) => (inView ? '1' : '0')};
`;

const Text = styled.div`
  font-family: 'Heebo';
  font-size: 3rem;
`;

const Italic = styled.span`
  font-family: 'Taviraj';
  font-size: 3rem;
  font-weight: bold;
  font-style: italic;
  line-height: 50%;
`;

const SubTitleWrapper = styled.div`
  position: relative;
  transform: translateY(${({ inView }) => (inView ? '0' : '-3rem')});
  opacity: ${({ inView }) => (inView ? '1' : '0')};
  transition: all 1.5s ease 0.3s;
`;

const PenImage = styled.img`
  width: 13.7rem;
  position: absolute;
  left: -0.2rem;
  top: -0.2rem;
`;

const SubTitle = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 2rem;
  /* margin-left: 0.6rem; */
  position: relative;
  line-height: 2;
`;

const Mark = styled.mark`
  font-size: 1.4rem;
  font-weight: bold;
`;

const DescriptionWrap = styled.div`
  transform: translateY(${({ inView }) => (inView ? '0' : '-3rem')});
  opacity: ${({ inView }) => (inView ? '1' : '0')};
  transition: all 1.5s ease 0.3s;
`;

const Description = styled.div`
  font-size: 1.4rem;
  position: relative;
  margin-left: 0.6rem;
`;
